@tailwind base;
@tailwind components;
@tailwind utilities;

.heroBackground {
    background-image: url( https://images.pexels.com/photos/41951/solar-system-emergence-spitzer-telescope-telescope-41951.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260 );
    background-blend-mode: multiply;
}

.statusContainer {
    height: 100px;
}

.headerCont {
    width: 100%;
    display: inline-block;
    vertical-align: 50%;
}

.headerCont2 {
    display: inline-block;
}

.inLine {
    display: inline-block;
}

.outstandingColor {
    color: rgba(5, 150, 105);
}

.inProgressColor {
    color: rgba(5, 150, 105);
}